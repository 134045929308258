export const jsonData = {
    "openapi": "3.0.1",
    "info": {
        "title": "ADVATECH API",
        "description": "Your API Description",
        "license": {
            "name": "Apache 1.0",
            "url": "http://springdoc.org"
        },
        "version": "1.0.0"
    },
    "servers": [
        {
            "url": "http://192.168.0.34:6915/api",
            "description": "Generated server url"
        }
    ],
    "paths": {
        "/user-management/sync/manage-user-setup": {
            "post": {
                "tags": [
                    "user-api"
                ],
                "operationId": "incomingUserSetupRequest",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "type": "string"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/sync/manage-supplier-setup": {
            "post": {
                "tags": [
                    "supplier-api"
                ],
                "operationId": "incomingSupplierSetupRequest",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "type": "string"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/sync/manage-item-setup": {
            "post": {
                "tags": [
                    "item-api"
                ],
                "operationId": "incomingItemSetupRequest",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "type": "string"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/sync/manage-item-composition-setup": {
            "post": {
                "tags": [
                    "item-composition-api"
                ],
                "operationId": "incomingItemCompositionSetupRequest",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "type": "string"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/sync/manage-insurance-setup": {
            "post": {
                "tags": [
                    "insurance-api"
                ],
                "operationId": "incomingInsuranceSetupRequest",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "type": "string"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/sync/manage-customer-setup": {
            "post": {
                "tags": [
                    "customer-api"
                ],
                "operationId": "incomingCustomerSetupRequest",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "type": "string"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/sync/check-notice": {
            "post": {
                "tags": [
                    "notice-api"
                ],
                "operationId": "incomingSpNoticeCheckRequest",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "type": "string"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/sync/check-item-importation": {
            "post": {
                "tags": [
                    "item-importation-api"
                ],
                "operationId": "incomingSpItemImportationCheckRequest",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "type": "string"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/sync/check-item-classification": {
            "post": {
                "tags": [
                    "item-classification-api"
                ],
                "operationId": "incomingSpItemClassificationCheckRequest",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "type": "string"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/sync/check-device-initialization": {
            "post": {
                "tags": [
                    "device-initialization-api"
                ],
                "operationId": "incomingSpDeviceInitializationCheckRequest",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "type": "string"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/sync/check-code-classification": {
            "post": {
                "tags": [
                    "code-classification-api"
                ],
                "operationId": "incomingSpCodeClassificationCheckRequest",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "type": "string"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/supplier/sp/{spRefNum}": {
            "post": {
                "tags": [
                    "supplier-api"
                ],
                "operationId": "loadSupplierBySpRefNum",
                "parameters": [
                    {
                        "name": "spRefNum",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/supplier/id/{supplierId}/sp/{spRefNum}": {
            "post": {
                "tags": [
                    "supplier-api"
                ],
                "operationId": "loadSupplierBySupplierCodeAndSpRefNum",
                "parameters": [
                    {
                        "name": "supplierId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "spRefNum",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/notice/pin/{pin}/title/{noticeTitle}": {
            "post": {
                "tags": [
                    "notice-api"
                ],
                "operationId": "loadNoticeBySpRefNumAndNoticeTitle",
                "parameters": [
                    {
                        "name": "pin",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "noticeTitle",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/notice/pin/{pin}/number/{noticeNumber}": {
            "post": {
                "tags": [
                    "notice-api"
                ],
                "operationId": "loadNoticeBySpRefNumAndNoticeRefNum",
                "parameters": [
                    {
                        "name": "pin",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "noticeNumber",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/notice/pin/{pin}/date/{registrationDate}": {
            "post": {
                "tags": [
                    "notice-api"
                ],
                "operationId": "loadNoticeBySpRefNumAndRegistrationDate",
                "parameters": [
                    {
                        "name": "pin",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "registrationDate",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/notice/pin/{pin}": {
            "post": {
                "tags": [
                    "notice-api"
                ],
                "operationId": "loadNoticeBySpRefNum",
                "parameters": [
                    {
                        "name": "pin",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/notice/number/{noticeNumber}": {
            "post": {
                "tags": [
                    "notice-api"
                ],
                "operationId": "loadNoticeByNoticeRefNum",
                "parameters": [
                    {
                        "name": "noticeNumber",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/notice": {
            "post": {
                "tags": [
                    "notice-api"
                ],
                "operationId": "loadAllNotice",
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/itemImportation/pin/{pin}/task/{taskCode}": {
            "post": {
                "tags": [
                    "item-importation-api"
                ],
                "operationId": "loadItemImportationBySpRefNumAndTaskCode",
                "parameters": [
                    {
                        "name": "pin",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "taskCode",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/itemImportation/pin/{pin}/supplier/{supplierName}": {
            "post": {
                "tags": [
                    "item-importation-api"
                ],
                "operationId": "loadItemImportationBySpRefNumAndSupplierName",
                "parameters": [
                    {
                        "name": "pin",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "supplierName",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/itemImportation/pin/{pin}/status/{importItemStatusCode}": {
            "post": {
                "tags": [
                    "item-importation-api"
                ],
                "operationId": "loadItemImportationBySpRefNumAndImportItemStatusCode",
                "parameters": [
                    {
                        "name": "pin",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "importItemStatusCode",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/itemImportation/pin/{pin}/item/{itemName}": {
            "post": {
                "tags": [
                    "item-importation-api"
                ],
                "operationId": "loadItemImportationBySpRefNumAndItemName",
                "parameters": [
                    {
                        "name": "pin",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "itemName",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/itemImportation/pin/{pin}/hs/{hsCode}": {
            "post": {
                "tags": [
                    "item-importation-api"
                ],
                "operationId": "loadItemImportationBySpRefNumAndHsCode",
                "parameters": [
                    {
                        "name": "pin",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "hsCode",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/itemImportation/pin/{pin}/declaration/{declarationNumber}": {
            "post": {
                "tags": [
                    "item-importation-api"
                ],
                "operationId": "loadItemImportationBySpRefNumAndDeclarationNumber",
                "parameters": [
                    {
                        "name": "pin",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "declarationNumber",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/itemImportation/pin/{pin}/country/{originCountryCode}": {
            "post": {
                "tags": [
                    "item-importation-api"
                ],
                "operationId": "loadItemImportationBySpRefNumAndOriginCountryCode",
                "parameters": [
                    {
                        "name": "pin",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "originCountryCode",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/itemImportation/pin/{pin}/agent/{agentName}": {
            "post": {
                "tags": [
                    "item-importation-api"
                ],
                "operationId": "loadItemImportationBySpRefNumAndAgentName",
                "parameters": [
                    {
                        "name": "pin",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "agentName",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/itemImportation": {
            "post": {
                "tags": [
                    "item-importation-api"
                ],
                "operationId": "loadAllItemImportation",
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/itemComposition/sp/{spRefNum}/subSp/{subSpRefNum}": {
            "post": {
                "tags": [
                    "item-composition-api"
                ],
                "operationId": "loadItemCompositionBySpRefNumAndSubSpRefNum",
                "parameters": [
                    {
                        "name": "spRefNum",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "subSpRefNum",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/itemComposition/sp/{spRefNum}": {
            "post": {
                "tags": [
                    "item-composition-api"
                ],
                "operationId": "loadItemCompositionBySpRefNum",
                "parameters": [
                    {
                        "name": "spRefNum",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/itemComposition/code/{itemCompositionCode}/sp/{spRefNum}": {
            "post": {
                "tags": [
                    "item-composition-api"
                ],
                "operationId": "loadItemCompositionByItemCompositionCodeAndSpRefNum",
                "parameters": [
                    {
                        "name": "itemCompositionCode",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "spRefNum",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/itemClassification/taxCode/{taxationTypeCode}": {
            "post": {
                "tags": [
                    "item-classification-api"
                ],
                "operationId": "loadItemClassificationByTaxationTypeCode",
                "parameters": [
                    {
                        "name": "taxationTypeCode",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/itemClassification/level/{itemClassificationLevel}": {
            "post": {
                "tags": [
                    "item-classification-api"
                ],
                "operationId": "loadItemClassificationByItemClassificationLevel",
                "parameters": [
                    {
                        "name": "itemClassificationLevel",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/itemClassification/code/{itemClassificationCode}": {
            "post": {
                "tags": [
                    "item-classification-api"
                ],
                "operationId": "loadItemClassificationByItemClassificationCode",
                "parameters": [
                    {
                        "name": "itemClassificationCode",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/itemClassification": {
            "post": {
                "tags": [
                    "item-classification-api"
                ],
                "operationId": "loadAllItemClassification",
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/item/type/{itemTypeCode}/pin/{pin}/branch/{branchId}": {
            "post": {
                "tags": [
                    "item-api"
                ],
                "operationId": "loadItemByItemTypeCodeAndSpRefNumAndSubSpRefNum",
                "parameters": [
                    {
                        "name": "itemTypeCode",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "pin",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "branchId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/item/pin/{pin}/branch/{branchId}": {
            "post": {
                "tags": [
                    "item-api"
                ],
                "operationId": "loadItemBySpRefNumAndSubSpRefNum",
                "parameters": [
                    {
                        "name": "pin",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "branchId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/item/pin/{pin}": {
            "post": {
                "tags": [
                    "item-api"
                ],
                "operationId": "loadItemBySpRefNum",
                "parameters": [
                    {
                        "name": "pin",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/item/name/{itemStandardName}/pin/{pin}/branch/{branchId}": {
            "post": {
                "tags": [
                    "item-api"
                ],
                "operationId": "loadItemByItemStandardNameAndSpRefNumAndSubSpRefNum",
                "parameters": [
                    {
                        "name": "itemStandardName",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "pin",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "branchId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/item/code/{itemCode}/pin/{pin}/branch/{branchId}": {
            "post": {
                "tags": [
                    "item-api"
                ],
                "operationId": "loadItemByItemCodeAndSpRefNumAndSubSpRefNum",
                "parameters": [
                    {
                        "name": "itemCode",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "pin",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "branchId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/item/classification/{itemClassificationCode}/pin/{pin}/branch/{branchId}": {
            "post": {
                "tags": [
                    "item-api"
                ],
                "operationId": "loadItemByItemClassificationCodeAndSpRefNumAndSubSpRefNum",
                "parameters": [
                    {
                        "name": "itemClassificationCode",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "pin",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "branchId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/insurance/sp/{spRefNum}/subSp/{subSpRefNum}": {
            "post": {
                "tags": [
                    "insurance-api"
                ],
                "operationId": "loadInsuranceBySpRefNumAndSubSpRefNum",
                "parameters": [
                    {
                        "name": "spRefNum",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "subSpRefNum",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/insurance/sp/{spRefNum}": {
            "post": {
                "tags": [
                    "insurance-api"
                ],
                "operationId": "loadInsuranceBySpRefNum",
                "parameters": [
                    {
                        "name": "spRefNum",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/customer/sp/{spRefNum}/subSp/{subSpRefNum}": {
            "post": {
                "tags": [
                    "customer-api"
                ],
                "operationId": "loadCustomerBySpRefNumAndSubSpRefNum",
                "parameters": [
                    {
                        "name": "spRefNum",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "subSpRefNum",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/customer/sp/{spRefNum}": {
            "post": {
                "tags": [
                    "customer-api"
                ],
                "operationId": "loadCustomerBySpRefNum",
                "parameters": [
                    {
                        "name": "spRefNum",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/customer/id/{customerId}/sp/{spRefNum}/subSp/{subSpRefNum}": {
            "post": {
                "tags": [
                    "customer-api"
                ],
                "operationId": "loadCustomerByCustomerIdAndSpRefNumAndSubSpRefNum",
                "parameters": [
                    {
                        "name": "customerId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "spRefNum",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "subSpRefNum",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/codeClassification": {
            "post": {
                "tags": [
                    "code-classification-api"
                ],
                "operationId": "loadAllCodeClassification",
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/codeClassification/pin/{pin}": {
            "post": {
                "tags": [
                    "code-classification-api"
                ],
                "operationId": "loadCodeClassificationBySpRefNum",
                "parameters": [
                    {
                        "name": "pin",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/setting/codeClassification/pin/{pin}/branch/{branchId}": {
            "post": {
                "tags": [
                    "code-classification-api"
                ],
                "operationId": "loadCodeClassificationBySpRefNumAndSubSpRefNum",
                "parameters": [
                    {
                        "name": "pin",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    },
                    {
                        "name": "branchId",
                        "in": "path",
                        "required": true,
                        "schema": {
                            "type": "string"
                        }
                    }
                ],
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "*/*": {
                                "schema": {
                                    "type": "object"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/sale/sync/manage-sale": {
            "post": {
                "tags": [
                    "sale-api"
                ],
                "operationId": "incomingSaleRequest",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "type": "string"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/purchase/sync/manage-purchase": {
            "post": {
                "tags": [
                    "purchase-api"
                ],
                "operationId": "incomingPurchaseRequest",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "type": "string"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        },
        "/defect-item/sync/manage-defect-item": {
            "post": {
                "tags": [
                    "defect-item-api"
                ],
                "operationId": "incomingDefectItemRequest",
                "requestBody": {
                    "content": {
                        "application/json": {
                            "schema": {
                                "type": "string"
                            }
                        }
                    },
                    "required": true
                },
                "responses": {
                    "200": {
                        "description": "OK",
                        "content": {
                            "application/json": {
                                "schema": {
                                    "type": "string"
                                }
                            }
                        }
                    }
                }
            }
        }
    },
    "components": {}
}

const camelToSentence = (str) => {
    return str
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase())
      .trim();
  };

  // Extracting data and transforming into desired objects
 export const extractedData = Object.entries(jsonData.paths).map(
    ([path, details]) => {
      const method = Object.keys(details)[0];
      const { tags, requestBody, responses } = details[method];
      const label = tags[0];
      const operationId = details[method].operationId;
      const description = camelToSentence(operationId);
      const parameters = details[method]?.parameters || [];
      return {
        label,
        path,
        method,
        parameters,
        operationId,
        requestBody,
        responses,
        description,
      };
    }
  );

  console.log(extractedData);

 export const groupedData = extractedData.reduce((acc, curr) => {
    if (!acc[curr.label]) {
      acc[curr.label] = {
        label: curr.label,
        key: curr.operationId,
        children: [curr],
      };
    } else {
      acc[curr.label].children.push(curr);
    }
    return acc;
  }, {});

  // Converting groupedData object into an array of objects
 export const groupedDataArray = Object.values(groupedData);