import React from "react";
import { Drawer } from "antd";
import { useDocsProveider } from "../../../utils/ContextProvider";
import controller from "../controller/controller";
import Item from "antd/es/list/Item";
import { useNavigate, useLocation } from "react-router-dom";
import { groupedData } from "../controller/api";

function SideDrawer() {
  const { isDrowerOpen, setIsDrowerOpen } = useDocsProveider();
  const drawerItems = controller.sidebarItems;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const path = pathname.split('/').pop().replace(/-/g, ' ');

  const hideDrawer = () => {
    setIsDrowerOpen(false);
  };
  console.log('====================================');
  console.log(groupedData);
  console.log('====================================');
  return (
    <div>
      <Drawer title="ADVAPOS" onClose={hideDrawer} open={isDrowerOpen} size="default" placement="left">
        {Object.values(groupedData).map((item, index) => (
          <div key={item.key}>
            <p className="text-sm font-bold text-left">{item.label}</p>
            {item.children.map((item, index) => (
              <div
                onClick={() => {
                  navigate("/docs/reference/" + item.operationId);
                  hideDrawer();
                }}
                className="flex flex-row justify-between items-center hover:bg-blue-gray-50 rounded-md px-5 py-1"
                style={{
                  backgroundColor:item.operationId === path ?  "gray":""
                }}
              >
               <p className="lowercase text-xs mt-2">{item.description}</p>
                <div
              className="p-1 font-medium text-xs text-white w-8 text-center rounded-lg  shadow-xl"
              style={{
                backgroundColor:
                  item.method === "get"
                    ? "blue"
                    : item.method === "post"
                    ? "darkgray"
                    : "red",
              }}
            >
              {item.method}
            </div>
              </div>
            ))}
          </div>
        ))}
      </Drawer>
    </div>
  );
}

export default SideDrawer;
