import {
  pos,
  stock,
  sales,
  Inventory,
  purchace,
  Integration,
} from "../../../entryfiles/imagePaths";
import {
  ClusterOutlined,
  BranchesOutlined,
  BarChartOutlined,
  StockOutlined,
  AppstoreOutlined,
  CreditCardOutlined,
} from "@ant-design/icons";

export default class {
  static features = [
    {
      id: 1,
      title: "Sales",
      label: "Sales",
      icon: (
        <BarChartOutlined className="text-4xl font-bold -ml-6 mb-3 text-red-800" />
      ),
      data: [
        "Real-time updates of the sales transactions status",
        "Track sales trends and react to changes promptly",
        "Issue printed or electronic receipts",
        "Use your existing hardware to print POS thermal receipts.",
      ],
      description:
        "Our POS sales module enables recording of sales to customers in an easy and efficient manner. Generate compliant tax invoice e-receipts that can be sent via email or printed",
      image: stock,
    },
    {
      id: 2,
      title: "Items",
      label: "Items",
      icon: (
        <div>
          <AppstoreOutlined className="text-4xl font-bold -ml-6 mb-3 text-red-800" />
        </div>
      ),
      data: [
        "Manage stock levels,",
        "Transfer goods between branches",
        "Data is always synced in real-time",
        "compliant with KRA eTIMS requirements.",
      ],
      description:
        "Easily list or add all of your business products and services. Quickly create sales from these items, manage stock levels, and transfer goods between branches",
      image: pos,
    },

    {
      id: 3,
      title: "Inventory Management",
      label: "Inventory Management",
      icon: (
        <StockOutlined className="text-4xl font-bold -ml-6 mb-3 text-red-800" />
      ),
      data: [
        "Track stock levels in real time",
        "Receive automatic low stock alerts",
        "Send orders to suppliers and track stock receipts",
        "Transfer stock between your stores",
      ],
      description:
        "Manage all your purchase information from one single dashboard",
      image: Inventory,
    },
    {
      id: 4,
      title: "Purchase",
      label: "Purchase",
      data: [
        "Manage all your purchase information",
        "Items you buy from suppliers and have been associated with your KRA PIN will be visible on the AdvaPos dashboard",
        "Purchases will be auto-verified by AdvaPos on eTIMS to ensure they are genuine and have been generated correctly by the supplier.",
        "Easily map purchase information to your item list for easy tracking and stock updates. ",
      ],
      icon: (
        <CreditCardOutlined className="text-4xl font-bold -ml-6 mb-3 text-red-800" />
      ),
      description:
        "Manage all your purchase information from one single dashboard",
      image: purchace,
    },
    {
      id: 5,
      title: "Integrations",
      label: "Integrations",
      data: [
        "Use Any Programmable Language To Easily Integrate AdvaPOS API Into Your Business Exististing Systems",
        "We Will Help You Get Up And Running In Time",
        "All You Need Is AdvaPOS Account To Use The API Service.",
        "",
      ],
      icon: (
        <ClusterOutlined className="text-4xl font-bold -ml-6 mb-3 text-red-800" />
      ),
      description:
        "Manage all your purchase information from one single dashboard",
      image: Integration,
    },
    {
      id: 6,
      title: "Multi-store management",
      label: "Other Features",
      icon: (
        <BranchesOutlined className="text-4xl font-bold -ml-6 mb-3 text-red-800" />
      ),
      data: [
        "Compare performance of your stores",
        "Manage items and customers across multiple locations with a single account",
      ],
      description: "Grow your business from one to hundreds of stores",
      image: sales,
    },
    {
      id: 7,
      title: "Other Features",
      label: "Other Features",
      data: [
        "Manage your customer list ",
        "View items imported under your PIN from our dashboard.",
        "View vital information through KRA notices",
        " View all your branches from the  dashboard",
      ],
      icon: (
        <ClusterOutlined className="text-4xl font-bold -ml-6 mb-3 text-red-800" />
      ),
      description: "Other features offered from AdvaPos",
      image:
        "https://images.unsplash.com/photo-1605629921711-2f6b00c6bbf4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&h=1220&q=80", // Assuming the icon is available
    },
  ];
}
