export default class controller{
    static sidebarItems = [
        { 
            key:1,
            label:"Branches",
            children:[
                {
                    title:"Get list of byssiness branches",
                    type:"GET",
                    to:"/getBranches"
                },
                {
                    title:"Add Branch User",
                    type:"POST",
                    to:"/addBranchUser"
                },
            ]
        },
        {
            key:1,
            label:"Customers",
            children:[
                {
                    title:"Get a customer",
                    type:"GET",
                    to:"/getBranches"
                },
                {
                    title:"Add Branch Customer",
                    type:"POST",
                    to:"/addBranchUser"
                },
            ]
        },
    ]
}