import React from "react";
import { advaLogo } from "../../entryfiles/imagePaths";
import {
  Navbar,
  Typography,
} from "@material-tailwind/react";
import {
  MenuOutlined,
  CloseOutlined,
  LoginOutlined,
  HomeOutlined,
  BookOutlined,
  SearchOutlined
} from "@ant-design/icons";
import { useDocsProveider } from "../../../utils/ContextProvider";
import { useNavigate } from "react-router-dom";


function LoginButton() {
    return (
      <div className="hover:border-[0.1px] hover:shadow-sm p-1 rounded-lg cursor-pointer text-white">
        <span className="">Sign In <LoginOutlined /></span>
      </div>
    );
  }

const DocsHeader = () => {
    const [isNavOpen, setIsNavOpen] = React.useState(false);
    const { isDrowerOpen, setIsDrowerOpen } = useDocsProveider();
    const { isJumpToModalOpen, setIsJumpToModalOpen } = useDocsProveider();
    const navigate = useNavigate();

  const toggleIsNavOpen = () => setIsNavOpen((cur) => !cur);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setIsNavOpen(false)
    );
  }, []);
  return (
    <Navbar className="top-0 z-50 h-max max-w-full rounded-none py-2  lg:py-4 bg-black">
        <div className="max-w-screen-2xl mx-auto ">
          <div className="relative flex items-center justify-between text-white">
          
            <Typography
              as="a"
              href="#"
              className="mr-4 -ml-3 cursor-pointer py-1.5 font-medium flex"
            >
                <span
              // size="sm"
              color="blue-gray"
              // variant="text"
              onClick={toggleIsNavOpen}
              className="ml-auto text-center lg:hidden hover:bg-gray-300 rounded-lg cursor-pointer"
            >
              {isNavOpen ? (
                <MenuOutlined className="h-10 w-10 p-3 font-bold" type="lg"
                onClick={() => {setIsDrowerOpen(true)}}
                />
              ) : (
                <MenuOutlined className="h-10 w-10 p-3 font-bold" type="lg" 
                 onClick={() => {setIsDrowerOpen(true)}}
                />
              )}
            </span>
     
                 <img
                 src={advaLogo}
                 alt="Logo"
                 className="w-36 h-10 object-cover hidden lg:block"
               />
                <div className="fort-bold mt-2 block lg:hidden">
               API Reference
            </div>
            </Typography>
            <div className="hidden lg:block text-black">
            <div className="flex justify-between items-center max-w-screen-2xl mx-auto gap-3">
            <li className="list-none font-medium text-xs flex gap-3 mt-2">
                <ul className="hover:border-[0.1px] hover:shadow-sm p-2 rounded-lg text-white" onClick={() => navigate("/")}><HomeOutlined className="mr-1"/><span>Home</span></ul>
                <ul className="border-[0.1px] hover:shadow-sm p-2 rounded-lg text-white"><BookOutlined/> Api Reference </ul>
            </li>
            <div className="mt-2 bg-white px-2 py-1 rounded-lg text-black text-xs pr-16 cursor-pointer hover:shadow-xl"  onClick={() => setIsJumpToModalOpen(true)}><SearchOutlined/> Search</div>
         </div>
            </div>
            <LoginButton/>
           
          </div>
        </div>
         <hr  className="border-t-[0.04px] border-red-100 w-[100%] block lg:hidden"/>
         <div className="flex justify-between items-center max-w-screen-2xl mx-auto block lg:hidden">
            <li className="list-none font-medium text-xs flex gap-3 mt-2">
            <ul className="hover:border-[0.1px] hover:shadow-sm p-2 rounded-lg text-white"><HomeOutlined className="mr-1"/><span>Home</span></ul>
                <ul className="border-[0.1px] hover:shadow-sm p-2 rounded-lg text-white"><BookOutlined/> Api Reference </ul>
            </li>
            <div className="mt-2 bg-white px-2 py-1 rounded-lg text-black text-xs pr-16 cursor-pointer hover:shadow-xl"  onClick={() => setIsJumpToModalOpen(true)}><SearchOutlined/> Search</div>
         </div>
      </Navbar>
  )
}

export default DocsHeader