import React, { useState, useEffect } from "react";
import {
  RightCircleOutlined,
  LockOutlined,
  CheckCircleOutlined,
  CheckCircleTwoTone
} from "@ant-design/icons";
import { Collapse } from "@material-tailwind/react";
import { jsonData } from "../../components/sidebar/controller/api";
import { useParams } from "react-router-dom";
import { baseUrl } from "../../constants/baseUrl";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { CopyOutlined } from "@ant-design/icons";
import { Input, Space } from "antd";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import javaIcon from "../../assets/icons/icons8-java.svg";
import phpIcon from "../../assets/icons/icons8-php-16.png";
import pythonIcon from "../../assets/icons/icons8-python.svg";
import javaScriptIcon from "../../assets/icons/icons8-js.svg";
import shellIcon from "../../assets/icons/icons8-console-64.png";
import { CopyToClipboard } from "react-copy-to-clipboard";

const ApiDescriptionPage = () => {
  const [isResponseOpen, setIsResponseOpen] = useState(false);
  const [languageSelected, setLanguageSelected] = useState("Shell");
  const [pathParams, setPathParams] = useState({});
  const [copied, setCopied] = useState(false);
  const [bodyParams, setBodyParams] = useState({});
  const { ref } = useParams();

  const camelToSentence = (str) => {
    return str
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase())
      .trim();
  };

  const extractedData = Object.entries(jsonData.paths).map(
    ([path, details]) => {
      const method = Object.keys(details)[0];
      const { tags, requestBody, responses } = details[method];
      const label = tags[0];
      const operationId = details[method].operationId;
      const description = camelToSentence(operationId);
      const parameters = details[method]?.parameters || [];
      return {
        label,
        path,
        method,
        parameters,
        operationId,
        requestBody,
        responses,
        description,
      };
    }
  );

  const filteredData = extractedData.find(
    (dataItem) => dataItem.operationId === ref
  );

  useEffect(() => {
    extractPathParams(ref);
    extractBodyParams(ref);
  }, [ref]);

  const extractBodyParams = (ref) => {
    const filteredData = extractedData.find(
      (dataItem) => dataItem.operationId === ref
    );
    if (filteredData) {
      const bodyParameters = filteredData.parameters.filter(
        (param) => param.in === "body"
      );
      const params = {};
      bodyParameters.forEach((param) => {
        params[param.name] = ""; // Initialize parameter value
      });
      setBodyParams(params);
    } else {
      setBodyParams({});
    }
  };

  const extractPathParams = (ref) => {
    const filteredData = extractedData.find(
      (dataItem) => dataItem.operationId === ref
    );
    if (filteredData) {
      const pathParts = filteredData.path.split("/");
      const params = {};
      pathParts.forEach((part, index) => {
        if (part.startsWith("{") && part.endsWith("}")) {
          const paramName = part.substring(1, part.length - 1);
          params[paramName] = ""; // Initialize parameter value
        }
      });
      setPathParams(params);
    } else {
      setPathParams({});
    }
  };

  // Function to generate code snippet based on selected language
  const generateCodeSnippet = () => {
    const urlWithParams = filteredData.path.replace(
      /{([^}]+)}/g,
      (match, paramName) => {
        return pathParams[paramName] || match;
      }
    );
    const bodyParamsString = Object.keys(bodyParams)
      .map((paramName) => `${paramName}: ${bodyParams[paramName]}`)
      .join(", ");

    switch (languageSelected) {
      case "Shell":
        return `curl --request ${
          filteredData.method
        } \n --url   ${baseUrl}${urlWithParams} \n --header 'accept: application/json' \n --header 'content-type: application/json' \n --data '${JSON.stringify(
          bodyParamsString
        )}'`;
      case "Java":
        return `OkHttpClient client = new OkHttpClient();
Request request = new Request.Builder()
.url(${baseUrl}${filteredData.path})
.${filteredData.method}(null)
.addHeader("accept", "application/json")
.addHeader("content-type", "application/json")
.build();
        
Response response = client.newCall(request).execute();`;
      case "JavaScript":
        return `const axios = require('axios');
const options = {
      method: ${filteredData.method},
      url: ${baseUrl}${filteredData.path},
      headers: {accept: 'application/json', 'content-type': 'application/json'}
};
axios
  .request(options)
  .then(function (response) {
    console.log(response.data);
  })
  .catch(function (error) {
    console.error(error);
  });`;
      case "Python":
        return `import requests
url = ${baseUrl}${filteredData.path}
headers = {
    "accept": "application/json",
    "content-type": "application/json"
}
response = requests.${filteredData.method}(url, headers=headers)
print(response.text)
        `;
      default:
        return `// Code snippet not available for ${languageSelected}`;
    }
  };

  const handleCopy = () => {
    setCopied(false);
    setTimeout(() => {
      setCopied(true);
    }, 100);
  };
  console.log(bodyParams);
  return (
    <div className="flex flex-col sm:flex-col lg:flex-row max-w-screen-lg">
      <div className="w-full sm:w-full lg:w-1/2 border-r-[0.3px] border-blue-gray-200">
        <div className="p-5 ">
          <p className="font-bold text-lg ">{filteredData.label}</p>
          <div className="mt-3 flex flex-col ">
            <div className="p-1 font-medium text-xs text-white w-12 text-center rounded-xl opacity-60 mt-2 bg-gray-700 uppercase">
              {filteredData.method}
            </div>
            <p className="mt-1 text-xs truncate max-w-xs lg:max-w-none lg:text-sm font-semibold ">
              {baseUrl + filteredData.path}
            </p>
          </div>
          <p className="text-xs mt-4 mb-8">{filteredData.description}</p>
          <hr />
          {filteredData.parameters?.length === 0 ? (
            <div></div>
          ) : (
            <div className="">
              <p className="text-xs mt-2 font-semibold mb-2">PATH PARAMS</p>
              <div className="bg-blue-gray-50 rounded-lg p-3 border-[1px] border-blue-gray-100">
                {Object.keys(pathParams).map((param, index) => (
                  <div
                    key={param}
                    className="border-b-[1px] border-blue-gray-100 py-1 flex justify-between items-center text-xs"
                  >
                    <label htmlFor={param}>
                      {param} {filteredData.parameters[index]?.schema?.type}
                      {filteredData.parameters[index]?.required && (
                        <span className="text-red-200"> required</span>
                      )}
                    </label>
                    <input
                      type="text"
                      id={param}
                      value={pathParams[param]}
                      className="border-[1px] border-blue-gray-100 rounded-sm px-1 py-1"
                      onChange={(e) =>
                        setPathParams({
                          ...pathParams,
                          [param]: e.target.value,
                        })
                      }
                    />
                  </div>
                ))}
              </div>
              {Object.keys(bodyParams).length !== 0 && (
                <div>
                  <p className="text-xs mt-2 font-semibold mb-2">BODY PARAMS</p>
                  {Object.keys(bodyParams).map((param) => (
                    <div key={param}>
                      <label htmlFor={param}>{param}:</label>
                      <input
                        type="text"
                        id={param}
                        value={bodyParams[param]}
                        onChange={(e) =>
                          setBodyParams({
                            ...bodyParams,
                            [param]: e.target.value,
                          })
                        }
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

          <div
            className={
              "mt-8  bg-blue-gray-50 p-4 flex  justify-between items-center border-[1px] border-blue-gray-100  hover:bg-blue-gray-100 hover:shadow-lg " +
              (isResponseOpen ? "rounded-t-lg" : "rounded-lg")
            }
            onClick={() => setIsResponseOpen(!isResponseOpen)}
          >
            <div>
              <span className="flex items-center gap-1 font-semibold">
                {" "}
                <div className="w-3 h-3 rounded-full bg-green-600 "></div> 200
              </span>
              Success
            </div>
            <div>
              <RightCircleOutlined size={20} />
            </div>
          </div>
          {isResponseOpen && (
            <div className=" bg-blue-gray-50 border-[1px] border-blue-gray-100 rounded-b-lg">
              <div className="text-xs p-4">RESPONSE BODY</div>
              <div className=" border-[1px] border-blue-gray-100 rounded-lg mx-2 px-1 py-1 mb-1 text-xs">
                OBJECT
                <div className="p-1 py-2 text-center rounded-lg border-[1px] border-blue-gray-100">
                  <p className="font-bold p-1">application/json</p>
                  <p>schema type: object</p>
                  <span className="flex items-center justify-center gap-1 font-semibold">
                    {" "}
                    <div className="w-3 h-3 rounded-full bg-green-600 "></div>{" "}
                    200
                  </span>
                </div>
              </div>
              <hr />
            </div>
          )}
        </div>
      </div>
      <div className="w-full sm:w-full lg:w-1/2">
        <div className="p-5">
          <p className="font-bold text-lg ">Language</p>
          <div className="flex items-center gap-3 mt-2">
            <div
              className={
                "flex items-center justify-center flex-col text-xs cursor-pointer p-2 rounded-md hover:bg-blue-gray-100 hover:border-blue-gray-200 hover:border-2" +
                (languageSelected === "Shell"
                  ? "bg-blue-gray-200 border-blue-gray-200 border-[1px]"
                  : "")
              }
              onClick={() => setLanguageSelected("Shell")}
            >
              <img
                className="shadow-xl shadow-gray-200 rounded-xl h-[28px] w-full object-cover"
                src={shellIcon}
                alt="Image Description"
              />
              Shell
            </div>
            <div
              className={
                "flex items-center justify-center text-xs flex-col cursor-pointer p-2 rounded-md hover:bg-blue-gray-100 hover:border-blue-gray-200 hover:border-2" +
                (languageSelected === "Java"
                  ? "bg-blue-gray-200 border-blue-gray-200 border-[1px]"
                  : "")
              }
              onClick={() => setLanguageSelected("Java")}
            >
              <img
                className="shadow-xl shadow-gray-200 rounded-xl h-[28px] w-full object-cover"
                src={javaIcon}
                alt="Image Description"
              />
              Java
            </div>
            <div
              className={
                "flex items-center justify-center text-xs flex-col cursor-pointer p-2 rounded-md hover:bg-blue-gray-100 hover:border-blue-gray-200 hover:border-2" +
                (languageSelected === "JavaScript"
                  ? "bg-blue-gray-200 border-blue-gray-200 border-[1px]"
                  : "")
              }
              onClick={() => setLanguageSelected("JavaScript")}
            >
              <img
                className="shadow-xl shadow-gray-200 rounded-xl h-[28px] w-fu object-cover"
                src={javaScriptIcon}
                alt="Image Description"
              />
              JavaScript
            </div>
            <div
              className={
                "flex items-center justify-center flex-col text-xs cursor-pointer p-2 rounded-md hover:bg-blue-gray-100 hover:border-blue-gray-200 hover:border-2" +
                (languageSelected === "Python"
                  ? "bg-blue-gray-200 border-blue-gray-200 border-[1px]"
                  : "")
              }
              onClick={() => setLanguageSelected("Python")}
            >
              <img
                className="shadow-xl shadow-gray-200 rounded-xl h-[28px]  object-cover"
                src={pythonIcon}
                alt="Image Description"
              />
              Python
            </div>
          </div>
        </div>
        <div className="w-full px-4 py-3">
          <div className="flex flex-row justify-between items-center text-xs px-1 py-2">
            <p className="uppercase">Authorization</p>{" "}
            <p className="uppercase">Header</p>
          </div>
          <Space.Compact size="middle" className="w-full">
            <Input
              addonBefore="header"
              placeholder="Username"
              addonAfter={<LockOutlined />}
            />
          </Space.Compact>
        </div>
        <div className="mx-3 ">
          <SyntaxHighlighter
            language={languageSelected.toLowerCase()}
            style={materialDark}
            className="rounded-lg"
          >
            {generateCodeSnippet()}
          </SyntaxHighlighter>
          <div className="bg-black -mt-2 opacity-90 py-1 px-2 flex justify-between items-center">
            <div>
              {copied ? (
                 <CheckCircleOutlined className="text-green-900" />              
              ) : (
                <CopyToClipboard text={generateCodeSnippet()} onCopy={handleCopy}>
                 <CopyOutlined className="text-white" />
                 </CopyToClipboard>
              )}
            </div>
              <button className="bg-red-800 text-xs text-white fornt-bold rounded-lg p-2">
                Try It!
              </button>
          </div>
        </div>
        <div className="bg-blue-gray-50 border-[1px] border-blue-gray-100 rounded-lg mx-4 mt-4">
          <div className="text-xs py-2 px-2 border-b-[1px] border-blue-gray-100">
            RESPONSE{" "}
          </div>
          <hr />
          <div className="text-xs text-center p-4">
            Click <span className="bg-gray-400 p-1 rounded-lg">Try It!</span> to
            start a request and see the response here! Or
            <br />
            choose an example:
            <div className="p-1 py-2 text-center rounded-lg ">
              <p>application/json</p>
              <span className="flex items-center justify-center gap-1 font-semibold">
                {" "}
                <div className="w-3 h-3 rounded-full bg-green-600 "></div> 200
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiDescriptionPage;
