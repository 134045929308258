import "./App.css"
import AppRouter from "./router";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { DocsContextProvider } from "./utils/ContextProvider";


function App() {
  return (
    <div>
      <DocsContextProvider>
        <BrowserRouter>
          <AppRouter />
        </BrowserRouter>
        </DocsContextProvider>
    </div>
  );
}

export default App;

