import React, { useEffect, useState } from "react";
import { Collapse } from "antd";
import { useNavigate,useLocation } from "react-router-dom";
import { useDocsProveider } from "../../../utils/ContextProvider";
import { CaretRightOutlined } from '@ant-design/icons';
import { jsonData } from "../controller/api";
// import SwaggerParser from "swagger-parser";

const SideBar = () => {
  const navigate = useNavigate();
  const { isJumpToModalOpen, setIsJumpToModalOpen } = useDocsProveider();
  const [swaggerData, setSwaggerData] = useState(null);
  const { pathname } = useLocation();
  const path = pathname.split('/').pop().replace(/-/g, ' ');

  async function fetchSwaggerData() {
    try {
      const response = await fetch("http://192.168.0.34:6915/api/v3/api-docs");
      const swaggerJson = await response.json();
    } catch (error) {
      console.error("Error fetching Swagger documentation:", error);
    }
  }

  const camelToSentence = (str) => {
    return str
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase())
      .trim();
  };
  useEffect(() => {}, []);
  // Extracting data and transforming into desired objects
  const extractedData = Object.entries(jsonData.paths).map(
    ([path, details]) => {
      const method = Object.keys(details)[0];
      const { tags, requestBody, responses } = details[method];
      const label = tags[0];
      const operationId = details[method].operationId;
      const description = camelToSentence(operationId);
      const parameters = details[method]?.parameters || [];
      return {
        label,
        path,
        method,
        parameters,
        operationId,
        requestBody,
        responses,
        description,
      };
    }
  );

  console.log(extractedData);

  const groupedData = extractedData.reduce((acc, curr) => {
    if (!acc[curr.label]) {
      acc[curr.label] = {
        label: curr.label,
        key: curr.operationId,
        children: [curr],
      };
    } else {
      acc[curr.label].children.push(curr);
    }
    return acc;
  }, {});

  // Converting groupedData object into an array of objects
  const groupedDataArray = Object.values(groupedData);

  const sidebarItems = Object.values(groupedData).map((group) => ({
    key: group.key,
    label: group.label,
    children: (
      <div className="">
        {group.children.map((item, index) => (
          <div
            onClick={() => navigate("/docs/reference/" + item.operationId)}
            className="flex flex-row justify-between items-center hover:bg-blue-gray-50 rounded-md px-5 py-1"
            key={index}
            style={{
              backgroundColor:item.operationId === path ?  "gray":""
            }}
          >
            <p className="lowercase text-xs mt-1">{item.description}</p>
            <div
              className="p-1 font-medium text-xs text-white w-8 text-center rounded-lg  mt-1 shadow-xl"
              style={{
                backgroundColor:
                  item.method === "GET"
                    ? "blue"
                    : item.method === "post"
                    ? "darkgray"
                    : "red",
              }}
            >
              {item.method}
            </div>
          </div>
        ))}
      </div>
    ),
  }));

  return (
    <div className="border-r-[0.3px] border-blue-gray-200 w-[300px] px-1 h-[100vh] overflow-y-scroll hidden lg:block md:hidden sm:hidden">
      <div className="px-4 ">
        <div
          className="mt-2 bg-white  py-1 rounded-md px-1 text-black text-xs  cursor-pointer hover:shadow-xl font-bold flex  justify-between items-center border-gray-100 border-[1.3px]"
          onClick={() => setIsJumpToModalOpen(true)}
        >
          <span>Jump to</span>{" "}
          <span className="bg-blue-gray-50 rounded-lg p-1">CTRL -/</span>
        </div>
      </div>

      <div className="mt-3 px-4">
        <p className="text-xs font-medium">ADVAPOS</p>
      </div>

      <div className="mt-3">
        <Collapse
          items={sidebarItems}
          defaultActiveKey={[path]}
          bordered={false}
          // defaultActiveKey={['1']}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          // activeKey={[path]}
          size="small"
          collapsible="header"
        />
      </div>
    </div>
  );
};

export default SideBar;
