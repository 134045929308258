import React from 'react';
import { BrowserRouter as Router, Navigate, useRoutes } from 'react-router-dom';
import { LandingPage } from "../pages/landingPage";
import DefaultDocsLayout from '../layout';
import ApiDescriptionPage from '../pages/docs/ApiDescriptionPage';

const AppRouter = () => {
  <Router></Router>;
    const routes = useRoutes([
      {
        path: "/",
        element: <LandingPage />,
      },
      {
        path: "/docs",
        element: 
        <DefaultDocsLayout /> 
        ,
        children: [
          { element: <Navigate to="/docs/reference/" />, index: true},
          { path: "reference/:ref", element:  <ApiDescriptionPage/> },
        ],
      },
    //   {
    //     path: "*",
    //     element: <PageNotFound />,
    //   },
    //   {
    //     path: "/404",
    //     element: <PageNotFound />,
    //   }
    ]);

    return routes;
}

export default AppRouter
