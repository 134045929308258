import React, { useEffect, useState } from "react";
import { RightOutlined } from "@ant-design/icons";
import controller from "../controller/controller";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Element, Link } from "react-scroll";

const variant = {
  visible: { scale: 1, transition: { duration: 1 } },
  hidden: { scale: 0.8 },
};

const Features = () => {
  const control = useAnimation();
  const [ref, inView] = useInView();
  const [selectedFeature, setselectedFeature] = useState(0);
  const [isSm, setIsSm] = React.useState(false);

  React.useEffect(() => {
    const handleResize = () => {
      setIsSm(window.innerWidth < 960);
    };

    handleResize(); 
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  const selectFeature = (id) => {
    setselectedFeature(id);
  };

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <Element name="Features" className="element p-10">
      <motion.div
        className="box"
        ref={ref}
        variants={variant}
        initial="hidden"
        animate={control}
      >
        <h1 className="text-xl font-bold mt-10 uppercase ">
          {" "}
          AdvaPos features
        </h1>
        <div className="flex justify-center items-center ">
          <div className="relative mt-3 w-1/6">
            <div className="absolute left-1/2 -top-[1.2px] transform -translate-x-1/2 bg-black w-20 h-1 "></div>
            <hr className="border-t-[1px] border-black" />
          </div>
        </div>
        <h2 className="text-sm text-gray-800 mt-2 sm:text-xs dark:text-gray-200">
          Features at a glance to match your unique needs
        </h2>
        {isSm ? (
          <div>
            {controller.features.map((feature, index) => (
              <div>
                <div className="mb-10 lg:mb-0 lg:col-span-6 lg:col-start-8 lg:order-2 px-10">
                  <div className="text-left mt-10">
                  {feature.icon}
                    <p className="font-bold text-3xl -ml-6 mb-1">
                      {feature.title}
                    </p>
                    <p className="font-normal -ml-6">{feature.description}</p>
                    <ul className="list-disc">
                      {feature.data.map((dat, index) => (
                        <li className="mt-3">{dat}</li>
                      ))}
                    </ul>
                    <p className=" text-base font-normal text-left -ml-5 mt-5 text-red-300 hover:translate-x-5 hover:text-red-800">
                        <Link
                          to="Contact"
                          spy={true}
                          smooth={true}
                          offset={50}
                          duration={500}
                        >
                          <a href="">
                            Explore and Learn more on{" "}
                            {controller.features[selectedFeature].title}{" "}
                            <RightOutlined className="text-xs " />
                          </a>
                        </Link>
                      </p>
                  </div>
                </div>
                <div className="lg:col-span-6">
                  <div className="relative">
                    <div>
                      <div
                        id="tabs-with-card-1"
                        role="tabpanel"
                        aria-labelledby="tabs-with-card-item-1"
                      >
                      <img
                          className="shadow-xl shadow-gray-200 rounded-xl h-[480px] w-full object-cover"
                          src={feature.image}
                          alt="Image Description"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div>
            <div className="flex justify-center flex-wrap gap-1 mt-5">
              {controller.features.map((feature, index) =>
                selectedFeature === index ? (
                  <button
                    className="border-b-2 text-lg border-red-700 text-red-700 shadow-md hover:border-transparent  hover:bg-gray-200 px-3  active w-32 text-center"
                    onClick={() => {
                      selectFeature(index);
                    }}
                  >
                    {feature.title}
                  </button>
                ) : (
                  <button
                    type="button"
                    key={feature.id}
                    onClick={() => {
                      selectFeature(index);
                    }}
                    className="border-b-2 border-gray-700   hover:bg-gray-200 p-4 md:p-5 text-center active w-32"
                  >
                    {feature.title}
                  </button>
                )
              )}
            </div>
            <div className="container mx-auto px-4 mt-5">
              <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
                <div className="relative z-10 lg:grid lg:grid-cols-12 lg:gap-16 ">
                  <div className="lg:col-span-6">
                    <div className="relative">
                      <div>
                        <div
                          id="tabs-with-card-1"
                          role="tabpanel"
                          aria-labelledby="tabs-with-card-item-1"
                        >
                          <img
                            className="shadow-xl shadow-gray-200 rounded-xl h-[480px] w-full object-cover"
                            src={controller.features[selectedFeature].image}
                            alt="Image Description"
                          />
                        </div>
                      </div>

                      <div className="hidden absolute top-0 end-0 translate-x-20 md:block lg:translate-x-20">
                        <svg
                          className="w-16 h-auto text-red-500"
                          width="121"
                          height="135"
                          viewBox="0 0 121 135"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5 16.4754C11.7688 27.4499 21.2452 57.3224 5 89.0164"
                            stroke="currentColor"
                            stroke-width="10"
                            stroke-linecap="round"
                          />
                          <path
                            d="M33.6761 112.104C44.6984 98.1239 74.2618 57.6776 83.4821 5"
                            stroke="currentColor"
                            stroke-width="10"
                            stroke-linecap="round"
                          />
                          <path
                            d="M50.5525 130C68.2064 127.495 110.731 117.541 116 78.0874"
                            stroke="currentColor"
                            stroke-width="10"
                            stroke-linecap="round"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="mb-10 lg:mb-0 lg:col-span-6 lg:col-start-8 lg:order-2">
                    <div className="text-left mt-3">
                      {controller.features[selectedFeature].icon}
                      <p className="font-bold text-3xl -ml-6 mb-3">
                        {controller.features[selectedFeature].title}
                      </p>
                      <p className="-ml-6 text-lg mb-3 font-medium">
                        {controller.features[selectedFeature].description}
                      </p>
                      <ul className="list-disc text-base font-normal">
                        {controller.features[selectedFeature].data.map(
                          (dat, index) => (
                            <li className="mt-3" key={index}>
                              {dat}
                            </li>
                          )
                        )}
                      </ul>
                      <p className=" text-base font-normal text-left -ml-5 mt-5 text-red-300 hover:translate-x-5 hover:text-red-800">
                        <Link
                          to="Contact"
                          spy={true}
                          smooth={true}
                          offset={50}
                          duration={500}
                        >
                          <a href="">
                            Explore and Learn more on{" "}
                            {controller.features[selectedFeature].title}{" "}
                            <RightOutlined className="text-xs " />
                          </a>
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </motion.div>
    </Element>
  );
};

export default Features;
