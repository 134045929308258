import { Collapse } from "antd";
import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import controller from "../controler/controller";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { Element } from "react-scroll";

const variant = {
  visible: { scale: 1, transition: { duration: 0.5 } },
  hidden: { scale: 0.7 },
};

const Faqs = () => {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <Element name="Faqs" className="element">
      <section className="bg-[#F9F9F9] p-10 mt-10  ">
        <motion.div
          className="box"
          ref={ref}
          variants={variant}
          initial="hidden"
          animate={control}
        >
          <div className="max-w-screen-2xl mx-auto">
            <h1 className="text-xl font-bold mt-10 uppercase text-black">
              FREQUENT ASKED QUESTIONS
            </h1>
            <div className="flex justify-center items-center ">
              <div className="relative mt-3 w-1/6">
                <div className="absolute left-1/2 -top-[1.2px] transform -translate-x-1/2 bg-black w-20 h-1 "></div>
                <hr className="border-t-[1px] border-black" />
              </div>
            </div>
            <Collapse
              items={controller.items}
              defaultActiveKey={["1"]}
              className="mt-5"
              // onChange={onChange}
              expandIcon={({ isActive }) =>
                isActive ? (
                  <div className="">
                    <MinusCircleOutlined className="font-bold text-red-800 text-xl mt-4" />
                  </div>
                ) : (
                  <div className="">
                    <QuestionCircleOutlined className="font-bold text-red-800 text-xl mt-4" />
                  </div>
                )
              }
            />
          </div>
        </motion.div>
      </section>
    </Element>
  );
};

export default Faqs;
