import { Avatar } from "antd";
import {
  EnvironmentOutlined,
  PhoneOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { Colors } from "../../../../constants/colors";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { Element } from "react-scroll";

const slideFromLeftVariant = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
    },
  },
  hidden: {
    opacity: 0,
    y: -40,
  },
};

const slideFromRightVariant = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
    },
  },
  hidden: {
    opacity: 0,
    y: 40,
  },
};

const ContactUs = () => {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <Element name="Contact" className="element">
      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div className="max-w-2xl lg:max-w-5xl mx-auto">
          <div className="text-center">
            <h1 className="text-xl font-bold mt-10 uppercase ">Contact US</h1>
            <div className="flex justify-center items-center ">
              <div className="relative mt-3 w-1/6">
                <div className="absolute left-1/2 -top-[1.2px] transform -translate-x-1/2 bg-black w-20 h-1 "></div>
                <hr className="border-t-[1px] border-black" />
              </div>
            </div>
            <p className="mt-1 text-gray-600 dark:text-gray-400">
              We'd love to talk about how we can help you.
            </p>
          </div>

          <div className="mt-12 grid items-center lg:grid-cols-2 gap-6 lg:gap-8 ">
            <motion.div
              className="box"
              ref={ref}
              variants={slideFromLeftVariant}
              initial="hidden"
              animate={control}
            >
              <div className="flex flex-col   p-4 sm:p-6 lg:p-8 shadow-xl">
                <h2 className="mb-8 text-xl font-semibold text-gray-800 dark:text-gray-200">
                  Fill in the form
                </h2>

                <form method="post">
                  <div className="grid gap-4">
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                      <div>
                        <label className="sr-only">Name</label>
                        <input
                          type="text"
                          name="hs-firstname-contacts-1"
                          id="hs-firstname-contacts-1"
                          className="py-3 px-4 block w-full border-[1px] border-gray-400 rounded-lg text-sm focus:border-red-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
                          placeholder=" Name"
                        />
                      </div>

                      <div>
                        <label className="sr-only">Phone Number</label>
                        <input
                          type="text"
                          name="hs-lastname-contacts-1"
                          id="hs-lastname-contacts-1"
                          className="py-3 px-4 block w-full border-[1px] border-gray-400 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                          placeholder="Phone Number"
                        />
                      </div>
                    </div>

                    <div>
                      <label className="sr-only">Email</label>
                      <input
                        type="email"
                        name="hs-email-contacts-1"
                        id="hs-email-contacts-1"
                        autocomplete="email"
                        className="py-3 px-4 block w-full border-[1px] border-gray-400 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                        placeholder="Email"
                      />
                    </div>

                    <div>
                      <label className="sr-only">Subject</label>
                      <input
                        type="text"
                        name="hs-phone-number-1"
                        id="hs-phone-number-1"
                        className="py-3 px-4 block w-full border-[1px] border-gray-400 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                        placeholder="Subject"
                      />
                    </div>

                    <div>
                      <label className="sr-only">Message</label>
                      <textarea
                        id="hs-about-contacts-1"
                        name="hs-about-contacts-1"
                        rows="4"
                        className="py-3 px-4 block w-full border-[1px] border-gray-400 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                        placeholder="Message"
                      ></textarea>
                    </div>
                  </div>

                  <div className="mt-4 grid">
                    <button
                      type="submit"
                      className="w-full redBlackradient py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-xl   text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none "
                    >
                      Send message
                    </button>
                  </div>

                  <div className="mt-3 text-center">
                    <p className="text-sm text-gray-500">
                      We'll get back to you in 1-2 business days.
                    </p>
                  </div>
                </form>
              </div>
            </motion.div>
            <motion.div
              className="box"
              ref={ref}
              variants={slideFromRightVariant}
              initial="hidden"
              animate={control}
            >
              <div className="divide-y divide-gray-200 shadow-xl p-5 border-t-2 border-black border-b-2">
                <div className="flex gap-x-3 py-4">
                  <Avatar className="bg-red-100 hover:bg-red-200" sizes={10}>
                    <EnvironmentOutlined className="text-red-800" />
                  </Avatar>
                  <div className="grow">
                    <h3 className="font-semibold text-gray-800 text-left text-sm -mt-1">
                      Physical Address:
                    </h3>
                    <p className="mt-1 text-xs text-gray-700 text-left ">
                      Soin Arcade Building, Westlands Rd Tumaini House Nyeri
                      Snowline Building Gakere Road, Nairobi, Kenya.
                    </p>
                  </div>
                </div>
                <div className="flex gap-x-3 py-4">
                  <Avatar className="bg-red-100 hover:bg-red-200" sizes={10}>
                    <MailOutlined className="text-red-800 text-lg" />
                  </Avatar>
                  <div className="grow">
                    <h3 className="font-semibold text-gray-800 text-left text-sm -mt-1">
                      Email:
                    </h3>
                    <p className="mt-1 text-xs text-gray-700 text-left">
                      info@advatech.co.ke
                    </p>
                  </div>
                </div>
                <div className="flex gap-x-3 py-4">
                  <Avatar className="bg-red-100 hover:bg-red-200" sizes={10}>
                    <PhoneOutlined className="text-red-800" />
                  </Avatar>
                  <div className="grow">
                    <h3 className="font-semibold text-gray-800 text-left text-sm -mt-1">
                      Mobile|Tel:
                    </h3>
                    <p className="mt-1 text-xs text-gray-700 text-left">
                      (254) 716 971 622 || (254) 0728 333 111 || (254) 020 4441813 {"  "} (24 hours calls accepted)
                    </p>
                  </div>
                </div>

                <div className="w-full h-64 bg-gray-200  overflow-hidden shadow-md border-b-[1px] border-red-500">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6176.359011643252!2d36.801074487921944!3d-1.2659863586954032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f17bef4b72f57%3A0x8f37a6ad52906e8e!2sSoin%20Shopping%20Arcade!5e0!3m2!1sen!2stz!4v1712222945781!5m2!1sen!2stz"
                    // width="600"
                    // height="450"
                    frameBorder="0"
                    allowfullscreen
                    loading="lazy"
                    className="w-full h-full"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default ContactUs;
