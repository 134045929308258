import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import React, { useEffect } from "react";
import { Button, Switch } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { Element, Link } from "react-scroll";
import { plans } from "../controller/controller";

const slideFromRightVariant = {
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1,
    },
  },
  hidden: {
    opacity: 0,
    y: 80,
  },
};

const PricingCard = ({ name, pricePerYear, features }) => {
  return (
    <div
      className={
        name == "STANDARD PLAN"
          ? `flex flex-col border text-center rounded-xl p-8 dark:border-gray-700 min-w-64 lg:min-w-96 border-red-400 relative`
          : `flex flex-col border border-gray-200 text-center rounded-xl p-8 dark:border-gray-700 min-w-64 lg:min-w-96 relative`
      }
    >
      <h4
        className={
          name == "STANDARD PLAN"
            ? `font-medium text-lg text-white bg-red-400 p-2 rounded-xl`
            : `font-medium text-lg text-gray-800 dark:text-gray-200`
        }
      >
        {name}
      </h4>
      <span className="mt-5 font-bold text-2xl text-gray-800 dark:text-gray-200">
        <span className="font-bold text-xl -me-2"></span>
        {pricePerYear.split("/")[0]}
      </span>
      <p className="mt-2 text-sm text-gray-500">{pricePerYear.split("/")[1]}</p>
      <ul className="mt-5 space-y-2.5 text-sm">
        {features?.map((feature) => (
          <li
            key={feature}
            className="flex space-x-1 items-center justify-start"
          >
            <svg
              className="flex-shrink-0 mt-0.5 size-3 text-red-600 "
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <polyline points="20 6 9 17 4 12" />
            </svg>
            <span className="text-gray-800 dark:text-gray-400 text-xs">
              {feature}
            </span>
          </li>
        ))}
      </ul>
      {name === "STANDARD PLAN" ? (
        <Link
          to="Contact"
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
          className=" px-20 mt-14 py-3  text-sm font-semibold rounded-xl border border-transparent bg-red-100 text-black hover:bg-red-200 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 float-end"
        >
          <a href="#" className="">
            Subscribe
          </a>
        </Link>
      ) : name === "BASIC PLAN" ? (
        <Link
          to="Contact"
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
          className=" px-20 mt-24 py-3  text-sm font-semibold rounded-xl border border-transparent bg-red-100 text-black hover:bg-red-200 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 float-end"
        >
          <a href="#" className="">
            Subscribe
          </a>
        </Link>
      ) : (
        <Link
          to="Contact"
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
          className=" px-20 mt-16 py-3  text-sm font-semibold rounded-xl border border-transparent bg-red-100 text-black hover:bg-red-200 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 float-end "
        >
          <a href="#" className="">
            Subscribe
          </a>
        </Link>
      )}
    </div>
  );
};
const featuresList = [
  "Users And Backoffice Admin",
  "User-Friendly interface",
  "Inventory Management",
  "Realtime Data Sync With KRA",
  "Ideal for Large-Sized Businesses",
  "Main Branch And Locations Setup",
  "Storage/Branch",
  "Advanced Security Features",
  "Email Receipts To Customers",
  "Unlimited Calls/Email Support",
  "WhatsApp Integration",
  "24/7 Live Chat Support",
  "Advanced Transaction Reporting",
  "Expanded Payment Options",
  "Annual Support",
];


const Pricing = () => {
  const control = useAnimation();
  const [ref, inView] = useInView();
  const [isMonthly, setIsMonthly] = React.useState(true);
  const lastIndex = featuresList.length - 1;


  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <Element name="Pricing" className="element">
      <div className="">
        <div className="max-w-screen-2xl px-4 py-10 sm:px-10 lg:px-8 lg:py-10 mx-auto">
          <motion.div
            className="box"
            ref={ref}
            variants={slideFromRightVariant}
            initial="hidden"
            animate={control}
          >
            <div className="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
              <h1 className="text-xl font-bold mt-10 uppercase"> Pricing</h1>
              <p className="mt-1 text-gray-600 dark:text-gray-400">
                Whatever your status, our offers evolve according to your needs.
              </p>

              <div className="flex justify-center items-center ">
                <div className="relative mt-3 w-1/6">
                  <div className="absolute left-1/2 -top-[1.2px] transform -translate-x-1/2 bg-black w-20 h-1 "></div>
                  <hr className="border-t-[1px] border-black" />
                </div>
              </div>
            </div>
            <div className="mt-12 justify-center lg:flex gap-6">
              <div className="container mx-auto p-4">
                {/* <div className="flex justify-center items-center mb-8">
                  <Switch
                    checked={isMonthly}
                    onChange={() => setIsMonthly(!isMonthly)}
                    checkedChildren="Monthly"
                    unCheckedChildren="Yearly"
                    className="mr-2"
                  />
                  <span className="ml-2 text-green-600 font-semibold">
                    Get 2 months free with annual plans
                  </span>
                </div> */}
                <div className="overflow-x-auto">
                  <table className="min-w-full bg-white">
                    <thead>
                      <tr>
                        <th className="py-2 px-4"></th>
                        {plans.map((plan, index) => (
                          <th
                            key={index}
                            className={`py-2 px-4 ${
                              index === 1
                                ? "bg-gray-300 text-black border-[1px]  border-red-900 border-r-1 border-l-1 border-b-0 border-t-1 "
                                : index === 2
                                ? "bg-gray-300"
                                : "bg-gray-200"
                            }`}
                          >
                            <div className="text-left">
                              <h2 className="text-xs my-1 font-bold">
                                {plan.name}
                              </h2>
                              <p className="text-base font-bold ">
                                {plan.pricePerYear}
                              </p>
                              <Link
                                to="Contact"
                                spy={true}
                                smooth={true}
                                offset={50}
                                duration={500}
                                className=" px-20 py-2  text-xs font-semibold rounded-lg border border-transparent bg-red-900 text-white hover:bg-red-200  float-left mt-4"
                              >
                                <a href="#" className="uppercase">
                                  Subscribe
                                </a>
                              </Link>
                            </div>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {featuresList.map((feature, idx) => (
                       
                        <tr key={idx} className="text-xs">
                          <td className="py-2 px-4 text-left">{feature}</td>
                          {plans.map((plan, index) => (
                            // const lastIdex = plans.lastIndexOf();
                            <td
                              key={index}
                              className={`py-2 px-4 text-center ${
                                index === 1
                                  ? "border-[1px]  border-red-900 border-r-1 border-l-1 border-t-0 "
                                  : index === 2  
                                  ? "bg-gray-100"
                                  : "bg-gray-50"
                              } ${ idx ===  lastIndex ?"border-b-1": "border-b-0"}`}
                            >
                              {plan.features.includes(feature) ?  <CheckOutlined />: <span>{plan.features[idx]}</span>}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

            
            </div>
              {/* {plans.map((pack) => (
                <PricingCard key={pack.name} {...pack} />
              ))} */}
          </motion.div>
        </div>
      </div>
    </Element>
  );
};

export default Pricing;
