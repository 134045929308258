import React from "react";
import {
  Navbar,
  Collapse,
  Typography,
  Button,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Avatar,
  Card,
  IconButton,
  Breadcrumbs,
} from "@material-tailwind/react";
import {
  MenuOutlined,
  CloseOutlined,
  LoginOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { advaLogo } from "../../../entryfiles/imagePaths";
import { Colors } from "../../../../constants/colors";
import { Link, Element } from "react-scroll";
import { useNavigate } from "react-router-dom";
import { socialHandlesAdvaTech } from "../../../../constants/socialHandles";

function LoginButton() {
  const navigate=useNavigate();
  return (
    <Button
    onClick={
      ()=>{
        window.location.href = 'https://www.portal.advapos.com';
      }
    }
    
    className="capitalize bg-red-900 rounded-xl hover:border-2">
      Sign In <LoginOutlined />
    </Button>
  );
}


// function NavListMenu(props) {
//   const [isMenuOpen, setIsMenuOpen] = React.useState(false);
//   const [active, setActive] = React.useState('Home');
//   const handleSetActive = (to) => {
//     console.log(to);
//     setActive(to);
//   };

//   return (
//     <React.Fragment>
//       <Menu allowHover open={isMenuOpen} handler={setIsMenuOpen}>
//         <MenuHandler>
//           <Link
//             activeClass="active"
//             to="Home"
//             spy={true}
//             smooth={true}
//             offset={50}
//             duration={500}
//             className="font-medium text-blue-gray-500"
//             onSetActive={handleSetActive}
//           >
//             <MenuItem className="hidden items-center gap-2 font-medium text-blue-gray-900 lg:flex lg:rounded-full"
//             style={{backgroundColor: active === "Home" ? "red":""}}
//             >
//               <HomeOutlined className="h-[18px] w-[18px] text-blue-gray-500" />{" "}
//               Home{" "}
//             </MenuItem>
//           </Link>
//         </MenuHandler>
//       </Menu>
//       <Link
//             activeClass="active"
//             to="Home"
//             spy={true}
//             smooth={true}
//             offset={50}
//             duration={500}
//             className="font-medium text-blue-gray-500"
//             onSetActive={handleSetActive}
//           >
//       <MenuItem className="flex items-center gap-2 font-medium text-blue-gray-900 lg:hidden" 
//        style={{backgroundColor: active === "Home" ? "red":""}}
//       onClick={props.onTouch}
//       >
//         {/*<Square3Stack3DIcon className="h-[18px] w-[18px] text-blue-gray-500" />{" "}*/}
//         Home <HomeOutlined />
//       </MenuItem>
//       </Link>
//     </React.Fragment>
//   );
// }

// nav list component
const navListItems = [

  {
    label: "About AdvaPos",
    icon: "UserCircleIcon",
    to: "About",
  },
  {
    label: "Features",
    icon: "CubeTransparentIcon",
    to: "Features",
  },
  {
    label: "Pricing",
    icon: "CodeBracketSquareIcon",
    to: "Pricing",
  },
  {
    label: "FAQs",
    icon: "CodeBracketSquareIcon",
    to: "Faqs",
  },
  {
    label: "Contact Us",
    icon: "CodeBracketSquareIcon",
    to: "Contact",
  },
  {
    label: "API",
    icon: "CodeBracketSquareIcon",
    to: "/docs/reference/incomingUserSetupRequest",
  },
];

function NavList(props) {
  const [active, setActive] = React.useState('About');
  const navigate = useNavigate();
  const handleSetActive = (to) => {
    console.log(to);
    setActive(to);
  };
  
  return (
    <ul className="mt-2 mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center">
      {/* <NavListMenu onTouch={props.onTouch}/> */}
      {navListItems.map(({ label, icon, to }, key) => (
        <Link
          activeClass="active"
          to={to}
          spy={true}
          smooth={true}
          offset={50}
          duration={500}
          onClick={() => {
            if (label == "API") {
              navigate("/docs/reference/incomingUserSetupRequest");
            }
          }}
          // className="font-medium text-blue-gray-500"
          onSetActive={handleSetActive}
        >
          <MenuItem className="flex items-center gap-2 lg:rounded-lg"
          style={{backgroundColor: active === to ? "rgb(183 28 28)":""}}
          onClick={props.onTouch}
          >
            {/*{React.createElement(icon, { className: "h-[18px] w-[18px]" })}{" "}*/}
            <span className="text-gray-900 font-semibold"
            style={{color: active === to ? "white":""}}
            > {label}</span>
          </MenuItem>
        </Link>
      ))}
    </ul>
  );
}

export default function Header() {
  const [isNavOpen, setIsNavOpen] = React.useState(false);

  const toggleIsNavOpen = () => setIsNavOpen((cur) => !cur);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setIsNavOpen(false)
    );
  }, []);

  return (
    <>
      <div
        style={{
          backgroundColor: Colors.secondary,
        }}
      >
        <div
          className={`sticky top z-10 h-10 max-w-screen-2xl mx-auto rounded-none py-1 px-4 lg:px-8 lg:py-2  lg:flex md:hidden sm:hidden hidden flex-row justify-between  text-white `}
        >
          <div className="flex flex-row gap-2">
            <div className="text-white flex flex-row">
              <Typography as="li" variant="small" className="p-1 font-">
                <a href="#" className="flex items-center text-xs">
                  AdvaTech – Powering Business Transformation
                </a>
              </Typography>
            </div>
          </div>
          <div className="flex flex-row">
            <Typography
              as="li"
              variant="small"
              className="p-1 font-body text-xs"
            >
              Office Hour : 08:30 am - 6:00pm | Sundays & Holiday Closed
            </Typography>
            <Breadcrumbs className="-mt-1 h-8 text-xs text-white" >
              <a href={socialHandlesAdvaTech.instagram} className="opacity-60 -mt-1  text-xs hover:text-red-900 text-black">
                instagram
              </a>
              <a href={socialHandlesAdvaTech.tweeter} className="opacity-60 -mt-1  text-xs hover:text-red-900 text-black">
                tweeter
              </a>
              <a href={socialHandlesAdvaTech.youTube} className="opacity-60 -mt-1 text-xs hover:text-red-900 text-black">
                youtube
              </a>
            </Breadcrumbs>
          </div>
        </div>
      </div>

      <Navbar className="sticky top-0 z-50 h-max max-w-full rounded-none px-4 py-2  lg:py-4 ">
        <div className="max-w-screen-2xl mx-auto ">
          <div className="relative mx-auto flex items-center justify-between text-blue-gray-900">
            <Typography
              as="a"
              href="#"
              className="mr-4 ml-2 cursor-pointer py-1.5 font-medium"
            >
              <img
                src={advaLogo}
                alt="Logo"
                className="w-40 h-10 object-cover"
              />
            </Typography>
            <div className="hidden lg:block">
              <NavList />
            </div>
            <span
              // size="sm"
              color="blue-gray"
              // variant="text"
              onClick={toggleIsNavOpen}
              className="ml-auto mr-2 text-center lg:hidden hover:bg-gray-300 rounded-lg cursor-pointer"
            >
              {isNavOpen ? (
                <CloseOutlined className="h-10 w-10 p-3 font-bold" type="lg" />
              ) : (
                <MenuOutlined className="h-10 w-10 p-3 font-bold" type="lg" />
              )}
            </span>
            <LoginButton />
          </div>
        </div>
        <Collapse open={isNavOpen} className="overflow-scroll">
          <NavList  onTouch={() => {setIsNavOpen(false)}}/>
        </Collapse>
      </Navbar>
    </>
  );
}
