import { Outlet } from "react-router-dom";
import React from "react";
import DocsHeader from "../components/header/view";
import SideBar from "../components/sidebar/view";
import SideDrawer from "../components/sidebar/view/SideDrawer";
import JumpToModal from "../components/dialogs/JumpToModal";

const DefaultDocsLayout = () => {
  return (
    <div className="h-[100vh]  ">
      <DocsHeader />
      <div className="flex flex-col lg:flex-row max-w-screen-2xl mx-auto">
        {/* Sidebar */}
        <div className="">
          <SideBar />
        </div>

        {/* Main Content */}
        <main className="flex-grow">
          <Outlet />
        </main>
      </div>
      <SideDrawer />
      <JumpToModal />
    </div>
  );
};

export default DefaultDocsLayout;
