import { useEffect, useState } from "react";
import { Header,HeroSection,AboutSection,Features,Pricing,Faqs,ContactUs,Footer } from '../../../components/landingPageComponents';

const LandingPage = () => {
    const [showTopIcon, setShowTopIcon] = useState(false);
    const [scrollDistance, setscrollDistance] = useState(window.scrollY);
  
    function scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  
    function handleScroll() {
      if (window.scrollY > 0) {
        setShowTopIcon(true);
      }
    }
  
    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
    }, [scrollDistance, showTopIcon]);
    
  return (
    <div className="App">
    <Header />
    <HeroSection />
    <AboutSection />
    <Features />
    <Pricing />
    <Faqs />
    <ContactUs />
    <Footer />

    {showTopIcon && (
      <div
        className="fixed bottom-0 right-8 bg-white text-red-800 p-3 rounded-full cursor-pointer animate-bounce"
        onClick={scrollToTop}
      >
        &#8593;
      </div>
    )}
  </div>
  )
}

export default LandingPage