import React, { useState, useMemo } from "react";
import { useDocsProveider } from "../../utils/ContextProvider";
import controller from "../sidebar/controller/controller";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import Search from "antd/es/input/Search";
import { groupedData } from "../sidebar/controller/api";
import Fuse from "fuse.js";

const JumpToModal = () => {
  const { isJumpToModalOpen, setIsJumpToModalOpen } = useDocsProveider();
  const [searchQuery, setSearchQuery] = useState("");
  const [searchedData, setsearchedData] = useState({});
  // const drawerItems = controller.sidebarItems;
  const navigate = useNavigate();

  const hideJumpModal = () => {
    setIsJumpToModalOpen(false);
  };

  const onSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const fused = useMemo(() => {
    const searchData = Object.values(groupedData).flatMap((group) => {
      const { children = [] } = group;
      return children.map((child) => ({
        ...child,
        key: group.label, // Use the group label as the key for filtering
      }));
    });

    return new Fuse(searchData, {
      keys: ["label", "description"],
    });
  }, [groupedData]);

  const data = useMemo(() => {
    const trimmedSQ = searchQuery?.trim();
    if (!trimmedSQ) return groupedData;

    const searchResults = fused.search(trimmedSQ).map((result) => result.item);

    const searchDataObject = searchResults.reduce((acc, item) => {
      if (!acc[item.key]) {
        acc[item.key] = [item];
      } else {
        acc[item.key].push(item);
      }
      return acc;
    }, {});

    return searchDataObject;
  }, [fused, searchQuery, groupedData]);

  console.log(data);

  return (
    <div>
      <Modal
        open={isJumpToModalOpen}
        onCancel={hideJumpModal}
        closable={false}
        footer={null}
      >
        <Search
          placeholder="Filter"
          onChange={onSearchChange} // Use onChange instead of onSearch
        />
        {searchQuery ? (
          <div>
            <p className="text-sm font-bold text-left mt-4">ADVAPOS</p>
            {Object.values(data).map((item, index) => (
              <div key={item.key}>
                {item?.map((item, index) => (
                  <div
                    onClick={() =>
                      navigate("/docs/reference/" + item.operationId)
                    }
                    className="flex flex-row justify-between items-center hover:bg-blue-gray-50 rounded-md "
                  >
                    <p className="font-semibold text-xs">{item.description}</p>
                    <div
                      className="p-1 font-medium text-xs text-white w-12 text-center rounded-xl opacity-60 mt-1"
                      style={{
                        backgroundColor:
                          item.method === "get"
                            ? "blue"
                            : item.method === "post"
                            ? "darkgray"
                            : "red",
                      }}
                    >
                      {item.method}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        ) : (
          <div>
            <p className="text-sm font-bold text-left mt-4">ADVAPOS</p>
            {Object.values(data).map((item, index) => (
              <div key={item.key}>
                {item?.children?.map((item, index) => (
                  <div
                    onClick={() =>
                      navigate("/docs/reference/" + item.operationId)
                    }
                    className="flex flex-row justify-between items-center hover:bg-blue-gray-50 rounded-md "
                  >
                    <p className="font-semibold text-xs">{item.description}</p>
                    <div
                      className="p-1 font-medium text-xs text-white w-12 text-center rounded-xl opacity-60 mt-1"
                      style={{
                        backgroundColor:
                          item.method === "get"
                            ? "blue"
                            : item.method === "post"
                            ? "darkgray"
                            : "red",
                      }}
                    >
                      {item.method}
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default JumpToModal;
