import { advaGroup, appStore, playStore } from "../../../entryfiles/imagePaths";
import {
  RightOutlined,
  PhoneFilled,
  EnvironmentOutlined,
  MailOutlined,
  InstagramOutlined,
  YoutubeOutlined,
  FacebookOutlined,
  LinkedinOutlined,
  PinterestOutlined
} from "@ant-design/icons";
import { socialHandlesAdvaTech } from "../../../../constants/socialHandles";

const Footer = () => {
  return (
    <section className="bg-[#0c0c0c] opacity-90">
      <div className="p-10   ">
        <div className="`max-w-screen-xl `mx-auto">
          <h1 className="text-xl font-bold mt-10 uppercase text-white">
            GET ADVAPOS APPLICATION TODAY
          </h1>
          <div className="flex justify-center items-center ">
            <div className="relative mt-3 w-1/6">
              <div className="absolute left-1/2 -top-[1.2px] transform -translate-x-1/2 bg-white w-20 h-1 "></div>
              <hr className="border-t-[1px] border-white" />
            </div>
          </div>
          <p className="text-center text-white mt-4 font-light pb-5">
            AdvaPos welcomes prospective partners, to enable businesses be
            compliant as quickly as possible.
          </p>
          <div className="flex justify-center items-center">
            <ul className="flex flex-row gap-4">
              <li className="btn-item-link hover:scale-105">
                <a
                  href="https://play.google.com/store/apps/details?id=com.advatech.advamauzo&pli=1"
                  className="btn-item-link hover:scale-105"
                  aria-label="Download from app store"
                >
                  <img
                    src={playStore}
                    width="185"
                    height="69"
                    alt="app store "
                    className="img"
                  />
                </a>
              </li>

              <li className="btn-item-link hover:scale-105">
                <a
                  href="#"
                  className="btn-item-link"
                  aria-label="Download from play store"
                >
                  <img
                    src={appStore}
                    width="185"
                    height="69"
                    alt="play store"
                    className="img"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <hr className="border-t-[1px] border-white opacity-5 max-w-screen-2xl mx-auto" />
      <div className="">
        <footer className="mt-auto bg-transparent w-full ">
          <div className="mt-auto w-full max-w-screen-2xl py-10 px-4 sm:px-6 lg:px-8 lg:pt-20 mx-auto ">
           <div className="flex justify-items-center items-center">
           <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-3 lg:ml-32">
              <div class="col-span-1">
                <a
                  class="flex-none text-xl font-semibold text-white"
                  href="#"
                  aria-label="Brand"
                >
                  <img
                    src={advaGroup}
                    width="185"
                    height="69"
                    alt="play store"
                    className="img"
                  />
                </a>
                <p className="text-left w-full text-white font-normal">
                  Advatech Group Limited, We are the Tech Company that is
                  passionate about using Technology to solve real-business
                  problems.
                </p>
              </div>

              <div class="col-span-1 text-left ml-4">
                <h4 class="font-semibold text-gray-100 text-sm">QUICK LINKS</h4>

                <div class="mt-3 grid space-y-3">
                  <p className=" hover:translate-x-1">
                    <a
                      class="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 text-left"
                      href="#"
                    >
                      <RightOutlined className="text-xs" /> Home
                    </a>
                    <hr className="border-t-[1px] border-white opacity-20 mt-1 w-2/3" />
                  </p>

                  <p className=" hover:translate-x-1">
                    <a
                      class="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 text-left"
                      href="#"
                    >
                      <RightOutlined className="text-xs" /> Features
                    </a>
                    <hr className="border-t-[1px] border-white opacity-20 mt-1 w-2/3" />
                  </p>
                  <p className="hover:translate-x-1">
                    <a
                      class="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 text-left"
                      href="#"
                    >
                      <RightOutlined className="text-xs" /> Pricing
                    </a>

                    <hr className="border-t-[1px] border-white opacity-20 mt-1 w-2/3" />
                  </p>
                  <p className="hover:translate-x-1">
                    <a
                      class="inline-flex gap-x-2 text-gray-400 hover:text-gray-200 text-left"
                      href="#"
                    >
                      <RightOutlined className="text-xs" /> Faqs
                    </a>
                    <hr className="border-t-[1px] border-white opacity-20 mt-1 w-2/3" />
                  </p>
                </div>
              </div>

              <div class="col-span-2 text-left sm:col-span-2 lg:col-span-1">
                <h4 class="font-semibold text-gray-100 text-sm">
                  SALES | SERVICES
                | SUPPORTS
                </h4>

                <div class="mt-3 grid space-y-3">
                  <p>
                    <a class=" text-gray-400 hover:text-gray-200" href="#">
                      ADVATECH KENYA
                      <span className="flex gap-1 items-center">
                        {" "}
                        <PhoneFilled className="text-xs" />{" "}
                        <span> +254 716 971 622</span>
                      </span>
                      <span className="flex gap-1 items-center">
                        {" "}
                        <PhoneFilled className="text-xs" />{" "}
                        <span> +254 728 333 111</span>
                      </span>
                      <span className="flex gap-1 items-center">
                        {" "}
                        <PhoneFilled className="text-xs" />{" "}
                        <span> +254 204 441 813</span>
                      </span>
                    </a>
                  </p>
                  <p>
                    <a
                      class=" gap-x-2 text-gray-400 hover:text-gray-200"
                      href="#"
                    >
                      {" "}
                      <EnvironmentOutlined /> Soin Arcade Building, Westlands Rd
                      Tumaini House, Nyeri Snowline Building Gakere Road,
                      Nairobi, Kenya
                    </a>
                  </p>
                  <p>
                    {" "}
                    <a
                      class="inline-flex gap-x-2 text-gray-400 hover:text-gray-200"
                      href="#"
                    >
                      {" "}
                      <MailOutlined /> info@advatech.co.ke
                    </a>
                  </p>
                </div>
              </div>

              <div class="col-span-2 ">
                <h4 class="font-semibold text-gray-100 text-left text-sm">
                  NEWSLETTER
                </h4>
                <form className="w-full lg:w-2/3">
                  <div class="mt-4 flex flex-col items-center gap-2 sm:flex-row sm:gap-3 bg-white rounded-lg p-2 ">
                    <div class="w-full">
                      <label for="hero-input" class="sr-only">
                        Search
                      </label>
                      <input
                        type="text"
                        id="hero-input"
                        name="hero-input"
                        class="py-3 px-4 block w-full border-transparent rounded-lg text-sm focus:border-red-500 focus:ring-red-500 disabled:opacity-50 disabled:pointer-events-none  "
                        placeholder="Enter your email"
                      />
                    </div>
                    <a
                      class="w-full sm:w-auto whitespace-nowrap p-3 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-800 text-white hover:bg-red-700 disabled:opacity-50 disabled:pointer-events-none"
                      href="#"
                    >
                      Subscribe
                    </a>
                  </div>
                </form>
              </div>
            </div>
           </div>
          
            <hr className="border-t-[1px] border-white opacity-5 max-w-screen-2xl mx-auto mt-5" />
            <div class="mt- sm:mt-1 grid gap-y-1 sm:gap-y-0 sm:flex sm:justify-between sm:items-center">
              <div class="flex justify-between items-center">
                <p class="text-sm text-gray-400">
                  © { new Date().getFullYear() } AdvaTech. All rights reserved.
                </p>
              </div>

              <div>
                <a
                  class="size-10 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white hover:bg-white/10 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-1 focus:ring-gray-600"
                  href={socialHandlesAdvaTech.facebook}
                >
                  <FacebookOutlined className="flex-shrink-0 size-4" />
                </a>
                <a
                  class="size-10 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white hover:bg-white/10 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-1 focus:ring-gray-600"
                  href={socialHandlesAdvaTech.instagram}
                >
                  <InstagramOutlined className="flex-shrink-0 size-4" />
                </a>
                <a
                  class="size-10 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white hover:bg-white/10 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-1 focus:ring-gray-600"
                  href={socialHandlesAdvaTech.youTube}
                >
                  <YoutubeOutlined className="flex-shrink-0 size-4" />
                </a>
                <a
                  class="size-10 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white hover:bg-white/10 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-1 focus:ring-gray-600"
                  href={socialHandlesAdvaTech.pintrest}
                >
                  <PinterestOutlined className="flex-shrink-0 size-4" />
                </a>
                <a
                  class="size-10 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent text-white hover:bg-white/10 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-1 focus:ring-gray-600"
                  href={socialHandlesAdvaTech.linkedIn}
                >
                  <LinkedinOutlined className="flex-shrink-0 size-4" />
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </section>
  );
};

export default Footer;
