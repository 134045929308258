import React, { createContext, useContext, useState } from "react";

const DocsContext = createContext(undefined);

export const useDocsProveider = () => {
  const context = useContext(DocsContext);
  if (!context) {
    throw new Error("useDocsProveider must be used within a DocsContext");
  }
  return context;
};


export const DocsContextProvider = ({ children }) => {
  const [isDrowerOpen, setIsDrowerOpen] = useState(false);
  const [isJumpToModalOpen, setIsJumpToModalOpen] = useState(false)

  return <DocsContext.Provider value={{ isDrowerOpen, setIsDrowerOpen,isJumpToModalOpen,setIsJumpToModalOpen }}>{children}</DocsContext.Provider>;
};
