import { Colors } from "../../../../constants/colors";
import { useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { Element } from "react-scroll";

const boxVariant = {
  visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
  hidden: { opacity: 0, scale: 0.6 },
};

const About = () => {
  const control = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      control.start("visible");
    } else {
      control.start("hidden");
    }
  }, [control, inView]);

  return (
    <Element  className="element">
      <section className="bg-[#D9DEE3] p-10 mt-10  redBlackradient">
        <motion.div
          className="box"
          ref={ref}
          variants={boxVariant}
          initial="hidden"
          animate={control}
        >
          <div className="max-w-screen-2xl mx-auto">
            <h1 className="text-xl font-bold mt-10 uppercase text-white ">
              About AdvaPos
            </h1>
            <div className="flex justify-center items-center ">
              <div className="relative mt-3 w-1/6">
                <div className="absolute left-1/2 -top-[1.2px] transform -translate-x-1/2 bg-white w-20 h-1 "></div>
                <hr className="border-t-[1px] border-white" />
              </div>
            </div>

            <p className="text-center text-white mt-4 font-normal pb-5 leading-relaxed">
              AdvaPOS is the best point-of-sale systems for shops, supermarket,
              grocery, and stores. The system track inventory at real time, as
              well as it provide thorough sales reporting, having good receipt
              options and are easy to use. AdvaPOS deal with huge amounts of
              inventory data and rapid turnover of that inventory, helping to
              track how much of each unit you sell and how much you have
              shelved. You might also want this to sync across several locations
              if you have more than one store.
            </p>
          </div>
        </motion.div>
      </section>
    </Element>
  );
};

export default About;
