export default class {
  static items = [
    {
      key: "1",
      label: (
        <p className="text-left text-sm font-bold py-2">
          What is AdvaPOS & AdvaMauzo
        </p>
      ),
      children: (
        <div className="text-left ">
          <p className="text-sm font-bold">AdvaPOS</p>
          <p className="text-xs mt-1 w-full lg:w-1/3 mb-1">
            ADVAPOS is an all in one point of Sale System that is already
            integrated with eTims suitable for all type of business.
          </p>
          <hr></hr>
          <p className="text-sm font-bold ">AdvaMauzo</p>
          <p className="text-xs mt-1  w-full lg:w-1/3">
            AdvaMauzo is an KRA certified eTims integrated point of sale
            solution for mobile phones and tablet. Just like AdvaPOS you can
            validate receipts for clients in the application. The application
            supports both Android and IOS Platforms.
          </p>
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <p className="text-left text-sm font-bold py-2">
          Can I Integrate AdvaPOS Account With Third Party Applications?
        </p>
      ),
      children: (
        <div className="text-left">
          <p className="text-sm font-bold">Yes,</p>
          <p className="text-xs mt-1 w-full lg:w-1/3 mb-1">
            Currently We Have Developed An API That Will Allow Third-Part
            Applications’ Integration To AdvaPOS Portal.
          </p>
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <p className="text-left  text-sm font-bold py-2">
          Can I know the procedures to get a new AdvaPOS or AdvaMauzo Mobile App
          account?
        </p>
      ),
      children: (
        <div className="text-left">
          <p className="text-sm font-bold">
            In order to get a new account, The following document must be
            submitted;
          </p>

          <div className=" border-[1px] border-gray-300 border-dashed mt-2 bg-gray-100 p-2 w-full lg:w-1/3">
            Taxpayer Name/Business Name/Company Name
          </div>
          <div className=" border-[1px] border-gray-300 border-dashed mt-2 bg-gray-100 p-2 w-full lg:w-1/3">
            PIN ID
          </div>
          <div className=" border-[1px] border-gray-300 border-dashed mt-2 bg-gray-100 p-2 w-full lg:w-1/3">
            Address
          </div>
          <div className=" border-[1px] border-gray-300 border-dashed mt-2 bg-gray-100 p-2 w-full lg:w-1/3">
            Email &
          </div>
          <div className=" border-[1px] border-gray-300 border-dashed mt-2 bg-gray-100 p-2 w-full lg:w-1/3">
            Telephone No.
          </div>
        </div>
      ),
    },
    {
      key: "4",
      label: (
        <p className="text-left text-sm font-bold py-2">
          How long does it take to get AdvaPOS or AdvaMauzo Mobile App account
          for my business?
        </p>
      ),
      children: (
        <div className="text-left">
          <p className="text-xs mt-1 w-full lg:w-1/3 mb-1">
            It Takes Almost 24 Hrs To Get Your Account Setup.
          </p>
        </div>
      ),
    },
  ];
}
