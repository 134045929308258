class PricingPackage {
    constructor(name, pricePerYear, features) {
      this.name = name;
      this.pricePerYear = pricePerYear;
      this.features = features;
    }
  }

  
  export  const plans = [new PricingPackage(
    "BASIC PLAN",
    "45000 KSH/1 year",
    [
      "1 User And Backoffice Admin",
      "User-Friendly interface",
      "Inventory Management",
      "Realtime Data Sync With KRA",
      "Ideal for Small Businespas",
      "Main Branch Configuration",
      "Up To 20GB Storage",
      "Advanced Security Features",
      "Email Receipts To Customers",
      "Limited Email/Calls Support",
      "WhatsApp Integration",
      "24/7 Live Chat Support",
      "Customized Reports",
      "Annual Support",
    ]
  ),
  new PricingPackage(
    "STANDARD PLAN",
    "65000 KSH/1 year",
    [
      "4 User And Backoffice Admin",
      "User-Friendly interface",
      "Inventory Management",
      "Realtime Data Sync With KRA",
      "Ideal For Medium-Sized Businesses",
      "Main Branch And 4 Locations Setup",
      "Up To 30GB Storage/Branch",
      "Advanced Security Features",
      "Email Receipts To Customers",
      "Unlimited Calls/Email Support",
      "WhatsApp Integration",
      "24/7 Live Chat Support",
      "Advanced Transaction Reporting",
      "Expanded Payment Options",
      "Annual Support",
    ]
  ),

 new PricingPackage(
    "PREMIUM PLAN",
    "85000 KSH/1 year",
    [
      "4 Users And Backoffice Admin",
      "User-Friendly interface",
      "Inventory Management",
      "Realtime Data Sync With KRA",
      "Ideal for Large-Sized Businesses",
      "Main Branch And 4 Locations Setup",
      "30GB Storage/Branch",
      "Advanced Security Features",
      "Email Receipts To Customers",
      "Unlimited Calls/Email Support",
      "WhatsApp Integration",
      "24/7 Live Chat Support",
      "Advanced Transaction Reporting",
      "Expanded Payment Options",
      "Annual Support",
    ]
  )
]

  