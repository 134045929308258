import React from "react";
import { Typography, Button } from "@material-tailwind/react";
import { LoginOutlined } from "@ant-design/icons";
import { heroImage, advaLogo } from "../../../entryfiles/imagePaths";
import { Element, Link } from "react-scroll";

const Hero = () => {
  return (
    <Element name="About" className="element">
      <section className="mb-20">
        <section
          className={`mt-24 mx-auto max-w-screen-2xl pb-8 px-4 items-center lg:flex md:px-8 bg-clip-text bg-gradient-to-l from-blue-600 to-violet-500`}
        >
          {/* Center the text content using flexbox */}
          <div className="flex flex-col space-y-8 sm:text-center lg:text-left animate-slideLeft">
            <h1 className="text-5xl font-bold xl:text-3xl text-center leading-tight">
              Unveiling the Future of <br />
              <span className="text-red-400">Point of Sale Innovation</span>
            </h1>
            <p className="text-gray-900 max-w-xl leading-relaxed mx-auto text-center">
              Experience the future of Point of Sale innovation. Discover
              solutions revolutionizing transactions. Embrace personalized
              customer experiences and streamlined operations. Join us as we
              redefine business-customer interactions in the digital age.
            </p>
            <div className="flex justify-center items-center space-y-3 sm:space-x-6 sm:space-y-0">
              <Button 
                onClick={
                  ()=>{
                    window.location.href = 'https://www.portal.advapos.com';
                  }
                }
              className="capitalize bg-red-900 rounded-xl hover:bg-white hover:border-black hover:border-2 hover:text-black mr-1">
                Sign In <LoginOutlined />
              </Button>
              <Link
                to="Contact"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                className=""
              >
                <Button className="capitalize rounded-xl hover:bg-white hover:border-red-900 hover:border-2 hover:text-black">
                  Get Started
                </Button>
              </Link>
            </div>
          </div>

          {/* Center the image using flexbox and margin adjustments */}
          <div className="flex-1 justify-center items-center mt-7 animate-slideRight">
            <img
              src={heroImage}
              className="w-full mx-auto sm:w-10/12 lg:w-full"
            />
          </div>
        </section>
      </section>
    </Element>
  );
};

export default Hero;
